//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import CodeCompose from '@/components/spec/code-compose/Compose';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';

export default {
  components: {
    CodeCompose,
    PageHeader
  },
  computed: {
    ...mapGetters('page', ['pageById']),
    currentPage() {
      return this.pageById(this.pageId) || {};
    },
    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    }
  },
  beforeRouteLeave(to, from, next) {
    const that = this;
    if (this.$ls.get('confirm-leave')) {
      this.$ls.remove('confirm-leave');
      return next();
    }
    if (to.name !== 'code-detail') {
      return this.$confirm({
        title: that.$t('Are you sure to leave this code detail?'),
        content: that.$t('The changed content will not be save.'),
        cancelText: that.$t('No, cancel'),
        okText: that.$t('Ok'),
        okType: 'primary',
        onOk() {
          next();
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
        width: 433
      });
    } else {
      return next();
    }
  },
  async created() {
    this.getPageById({ projectId: this.projectId, pageId: this.pageId });
  },
  methods: {
    ...mapActions('page', ['getPageById'])
  }
};
