var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "page" },
    [
      _c("page-header", {
        staticClass: "mb-xs",
        attrs: {
          "show-back-icon": "",
          title: _vm.currentPage.name,
          "show-status": "",
          status: _vm.currentPage.ispublic
        },
        on: {
          back: function($event) {
            return _vm.$router.go(-1)
          }
        }
      }),
      _c("code-compose", { attrs: { type: "compose-code", code: {} } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }